import {
  CREATE_USER_SUCCESS,
  CREATE_ROLE_SUCCESS,
  AUTH_SUCCESS,
  RESET_MESSAGES,
  UPDATE_ROLE_SUCCESS,
  DELETE_ROLE_SUCCESS,
  DELETE_USER_SUCCESS,
  CREATE_DRIVER_SUCCESS,
  CREATE_WAREHOUSE_SUCCESS,
  CREATE_WAREHOUSE_MANAGER_SUCCESS,
  CREATE_MERCHANT_SUCCESS,
  CREATE_SHOP_SUCCESS,
  CREATE_AREA_SUCCESS,
} from "../actions/types";

const initState = {
  createUserSuccess: "",
  createAreaSuccess: "",
  createRoleSuccess: "",
  createDriverSuccess: "",
  createMerchantSuccess: "",
  createShopSuccess: "",
  createWarehouseSuccess: "",
  createWarehouseManagerSuccess: "",
  updateRoleSuccess: "",
  deleteRoleSuccess: "",
  deleteUserSuccess: "",
  loginSuccess: "",
  updateSuccess: "",
  deleteSuccess: "",
  logoutSuccess: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        loginSuccess: action.payload,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserSuccess: action.payload,
      };
    case CREATE_AREA_SUCCESS:
      return {
        ...state,
        createAreaSuccess: action.payload,
      };
    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        createRoleSuccess: action.payload,
      };
    case CREATE_DRIVER_SUCCESS:
      return {
        ...state,
        createDriverSuccess: action.payload,
      };
    case CREATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        createWarehouseSuccess: action.payload,
      };
    case CREATE_WAREHOUSE_MANAGER_SUCCESS:
      return {
        ...state,
        createWarehouseManagerSuccess: action.payload,
      };
    case CREATE_MERCHANT_SUCCESS:
      return {
        ...state,
        createMerchantSuccess: action.payload,
      };
    case CREATE_SHOP_SUCCESS:
      return {
        ...state,
        createShopSuccess: action.payload,
      };

    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        updateRoleSuccess: action.payload,
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        deleteRoleSuccess: action.payload,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserSuccess: action.payload,
      };

    case RESET_MESSAGES:
      return initState;
    default:
      return state;
  }
};
