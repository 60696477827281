import React, { Component } from 'react'
import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb,Modal, Divider } from 'antd';
import CommonForm, { renderDate, renderInput, renderSelect, renderTextField } from '../../../component/form/form';

import { connect } from 'react-redux';
import { getRoles } from "../../../actions/role";
import { createUser } from "../../../actions/user";
import { useEffect, useState} from 'react';
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const gender = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'},
    {value: 'other', label: 'Other'},
  ]
  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]
  

  
const ModalForm = ({visible, setVisible,getRoles,createUser,refresh}) => {
      const [roles, setRoles] = useState(null)
      const [form] = Form.useForm();
      useEffect(async() => {
        const res = await getRoles();
        setRoles(res)
      }, [])
      const createRoles = (label,value) => {
        return {label,value}
      }
      const onFinish =async (values) => {
        // console.log('Success:', values);
        const code = await createUser(values)
        if(code === 201){
          form.resetFields();
          refresh()
          setVisible()
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      let option = [];
      option = (
        roles ? roles.map(item => 
          createRoles(item?.name , item?.name  ))
      : null)

    return ( 
          <Modal
          title="Create User"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
            
                {renderInput('user_id', 'User ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('full_name', 'Full Name', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('email', 'Email', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('mobile_number', 'Mobile', [
                    { required: true, message: 'This field is required!' },
                    { pattern: /^(01)[0-9][0-9]{8}$/, message: 'Please valid number!' }
                  ])
                }
                {renderInput('password', 'Password', [
                    { required: true, message: 'This field is required!' },
                    { pattern: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' }
                  ], '', 'password')
                }
                {renderSelect('status', 'Status', [
                    { required: true},
                  ],
                  'Select a option', status, 'none')
                }
                {roles ? renderSelect('role', 'Role', [
                    { required: true},
                  ],
                  'Select a option', option, 'none')
                : null}
                
                
                <Form.Item {...tailLayout}> 
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              
              </Form>

        </Modal>
     );
}
 
export default connect(null,{getRoles,createUser})(ModalForm);
