import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import './index.css';
import { Layout, Menu} from 'antd';
import {
  BookFilled,
  PieChartOutlined,
  UserOutlined,
  AppstoreOutlined,
  FileImageOutlined,
  PictureOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Logo from './logo.png'; 
const { Sider } = Layout;
const { SubMenu } = Menu;

class Sidebar extends Component {
  state = {
    collapsed: false,
    access: null
  };
componentDidMount = () => {
  this.setState({access: JSON.parse(localStorage.getItem('access'))})
}
  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  render() {
    const { collapsed,access } = this.state;
    return (
        <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse} style={{backgroundColor: '#ffffff'}}>
          <div className="logo" style={{backgroundColor: '#ffffff'}}>
            <img src={Logo} alt="logo" width="30%" />
          </div>
          <Menu theme="light" defaultSelectedKeys={['1']} mode="inline">
            <Menu.Item key="1" icon={<PieChartOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>

            {access?.user_management ?
            <SubMenu key="user" icon={<UserOutlined />} title="User Management">
              <Menu.Item key="role"><Link to="/roles">Role</Link></Menu.Item>
              <Menu.Item key="3"><Link to="/users">User</Link></Menu.Item>
            </SubMenu>
            :null}
            {access?.end_tv_app_user_management ?
            <SubMenu key="tv app" icon={<UserSwitchOutlined />} title="TV App Management">
              <Menu.Item key="/packages"><Link to="/packages">Packages</Link></Menu.Item>
              <Menu.Item key="app user"><Link to="/app-user">App User</Link></Menu.Item>
              <Menu.Item key="/storage"><Link to="/storage">Storage</Link></Menu.Item>
            </SubMenu>
            :null}
            {access?.tv_app_management ?
            <SubMenu key="Ad" icon={<PictureOutlined />} title="Ad Management">
              <Menu.Item key="Ad"><Link to="/add-list">Ad List</Link></Menu.Item>
              <Menu.Item key="doctors"><Link to="/doctors">Doctors</Link></Menu.Item>
              {/* <Menu.Item key="ib"><Link to="/image-build">Image Build</Link></Menu.Item> */}
            </SubMenu>
            :null}
           
          </Menu>
        </Sider>
    );
  }
}

 
export default Sidebar;