import React from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Breadcrumb, Select, Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import { EditOutlined, SearchOutlined, } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getStorage } from "../../../actions/storage";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import EditModalForm from './editModalForm';
const Search = Input.Search;
const { Option } = Select;

const StorageList = ({ getStorage }) => {
  const [data, setData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editData, setEditData] = useState([]);


  const getData = async () => {
    const res = await getStorage()
    setData(res);
  }

  useEffect(() => {
    getData()
  }, [])


  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };


  const handleEdit = async (item) => {
    setIsEditModalOpen(true);
    setEditData(item)
    // const code = await deleteUser(user);
    // if(code === 201 ){
    //   getData();
    // }
  }

  const columns = [
    {
      title: 'SL',
      dataIndex: 'sl',
      key: 'sl',
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'user_name',
      key: 'user_name',
      width: '20%',
      ...getColumnSearchProps('user_name'),
    },
    {
      title: 'Extra Use (MB)',
      dataIndex: 'extra_use',
      key: 'extra_use',
      width: '20%',
      ...getColumnSearchProps('extra_use'),
    },
    {
      title: 'Total (MB)',
      dataIndex: 'total',
      key: 'total',
      width: '20%',
      ...getColumnSearchProps('total'),
    },
    {
      title: 'Total Use (MB)',
      dataIndex: 'total_use',
      key: 'total_use',
      width: '20%',
    },
    {
      title: 'Generate Image Limit',
      dataIndex: 'total_generate_image',
      key: 'total_generate_image',
      width: '20%',
    },
    {
      title: 'Already Generate Image',
      dataIndex: 'already_generate_image',
      key: 'already_generate_image',
      width: '20%',
    },
    {
      title: 'Extra Generate Image',
      dataIndex: 'extra_generate_image',
      key: 'extra_generate_image',
      width: '20%',
    },
    {
      title: 'Device',
      dataIndex: 'total_device',
      key: 'total_device',
      width: '20%',
      ...getColumnSearchProps('total_device'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
    },
    {
      title: 'Create at',
      dataIndex: 'create_at',
      key: 'create_at',
      width: '20%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];

  const createData = (sl ,user_name, extra_use, total, total_use, total_generate_image, extra_generate_image,already_generate_image, total_device, status, create_at, action) => {
    return {sl ,user_name, extra_use, total, total_use, total_generate_image, extra_generate_image,already_generate_image, total_device, status, create_at, action};
  };

  const rows = [
    data
      ? data?.map((item, key) => createData(
        key + 1, 
        item?.user_name, 
        item?.extra_use,
        item?.total,
        item?.total_use,
        item?.total_generate_image,
        item?.extra_generate_image,
        item?.already_generate_image,
        item?.total_device,
        item.status ?
          <> 
            <Tag color="#87d068">Active</Tag> 
            {/* <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => handleStatus(item.app_id, false)}><Button size="small" type="danger" icon={<StopOutlined  />}>Disable</Button></Popconfirm> */}
          </>: 
          <>
            <Tag color="#f50">Inactive</Tag>
            {/* <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => handleStatus(item.app_id, true)}><Button size="small" type="primary" icon={<CheckCircleOutlined  />}>Activate</Button></Popconfirm> */}
          </>,
        item?.create_at,
        <>
          <Button type='primary' size="small" danger icon={<EditOutlined  />}  onClick={() => handleEdit(item)}>Edit</Button>
        </>
        ))
      : "",
      
  ];

  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Storage</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Storage"
        subTitle="TV App User Management"
      >
      </PageHeader>

      <br />  
      <br />  
      <Table columns={columns} pagination={false} dataSource={rows[0]} scroll={{ x: 1000 }} sticky/>
      <br />
      
     {isEditModalOpen ? 
      <EditModalForm 
        visible={isEditModalOpen} 
        setVisible={() => setIsEditModalOpen(false)}
        refresh={getData}
        data={editData}
      />
     :null}
    </Content>
   );
}
 
export default connect(null, {getStorage}) (StorageList);