import { GET_SINGLE_WAREHOUSE_MANAGER, GET_WAREHOUSE, GET_WAREHOUSES, GET_WAREHOUSE_MANAGER } from '../actions/types';

const initState = {
    allWarehoues: '',
    allWarehoueManager: '',
    singleWarehouse: '',
    singleManager: '',
    role: null,
}


export default (state=initState, action)=>{
    switch (action.type) {
        case GET_WAREHOUSES:
            return {...state, allWarehoues: action.payload};
            
        case GET_WAREHOUSE:
            return {...state, singleWarehouse: action.payload};
            
        case GET_WAREHOUSE_MANAGER:
            return {...state, allWarehoueManager: action.payload};
            
            
        case GET_SINGLE_WAREHOUSE_MANAGER:
            return {...state, singleManager: action.payload};
            
        default:
            return state;
    }
}