import { Form, Select } from 'antd';
import React from 'react'
const { Option } = Select;

const SelectBox = ({label,name,value, rules,placeholder,options,mode, ...rest}) => {
    return ( 
    <Form.Item
            name={name}
            label={label}
            rules={rules}
            
          >
            <Select
                showSearch
                placeholder={placeholder}
                size="middle"
                allowClear
                mode={mode}
                {...rest}
            >
                {options.map(item => (
                    <Option value={item.value}>{item.label}</Option>
                ))}
            </Select>
          </Form.Item> 
          );
}
 
export default SelectBox;