import {
    CREATE_USER_ERROR,
    CREATE_ROLE_ERROR,
    CREATE_AREA_ERROR,
    AUTH_ERROR,
    UPDATE_ROLE_ERROR,
    GET_ROLE_ERROR,
    RESET_MESSAGES,
    DELETE_ROLE_ERROR,
    DELETE_USER_ERROR,
    CREATE_DRIVER_ERROR,
    CREATE_WAREHOUSE_ERROR,
    CREATE_WAREHOUSE_MANAGER_ERROR,
    GET_USER_ERROR,
    GET_DRIVER_ERROR,
    GET_SINGLE_MANAGER_ERROR,
    CREATE_MERCHANT_ERROR,
    GET_MERCHANT_ERROR,
    CREATE_SHOP_ERROR,
    GET_SHOPS_ERROR
    
} from '../actions/types';

const initState = {
    createUserError: "",
    createAreaError: "",
    createMerchantError: "",
    createShopError: "",
    createRoleError: "",
    createDriverError: "",
    createWarehouseError: "",
    createWarehouseManagerError: "",
    
    loginError: "",
    updateRoleError: "",
    deleteRoleError: "",
    deleteUserError: "",

    getUserError: "",
    getShopsError: "",
    getRoleError: "",
    getDriverError: "",
    getManagerError: "",
    getMerchantError: "",
}

export default (state = initState, action) => {
    switch (action.type) {
        case AUTH_ERROR:
            return{
                ...state,
                loginError: action.payload,
            };
        case CREATE_AREA_ERROR:
            return{
                ...state,
                createAreaError: action.payload
            };
        case CREATE_USER_ERROR:
            return{
                ...state,
                createUserError: action.payload
            };
        case CREATE_MERCHANT_ERROR:
            return{
                ...state,
                createMerchantError: action.payload
            };
        case CREATE_SHOP_ERROR:
            return{
                ...state,
                createShopError: action.payload
            };
        case CREATE_ROLE_ERROR:
            return{
                ...state,
                createRoleError: action.payload
            };
        case CREATE_DRIVER_ERROR:
            return{
                ...state,
                createDriverError: action.payload
            };
        case GET_ROLE_ERROR:
            return{
                ...state,
                getRoleError: action.payload
            };
        case GET_USER_ERROR:
            return{
                ...state,
                getUserError: action.payload
            };
        case GET_DRIVER_ERROR:
            return{
                ...state,
                getDriverError: action.payload
            };
        case GET_SINGLE_MANAGER_ERROR:
            return{
                ...state,
                getManagerError: action.payload
            };
        case GET_MERCHANT_ERROR:
            return{
                ...state,
                getMerchantError: action.payload
            };
        case GET_SHOPS_ERROR:
            return{
                ...state,
                getShopsError: action.payload
            };
        case UPDATE_ROLE_ERROR:
            return{
                ...state,
                getUpdateError: action.payload
            };
        case DELETE_ROLE_ERROR:
            return{
                ...state,
                deleteRoleError: action.payload
            };
        case DELETE_USER_ERROR:
            return{
                ...state,
                deleteUserError: action.payload
            };
        
        case CREATE_WAREHOUSE_ERROR:
            return{
                ...state,
                createWarehouseError: action.payload
            };
        case CREATE_WAREHOUSE_MANAGER_ERROR:
            return{
                ...state,
                createWarehouseManagerError: action.payload
            };
        
            
        case RESET_MESSAGES:
            return initState;
        default:
            return state;
    }
}