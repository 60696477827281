import React, { Component } from 'react'
import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb, Divider, Row, Col } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import { TabletFilled } from '@ant-design/icons';
import history from '../../../history';
import CommonForm from '../../../component/form/form';
import { connect } from 'react-redux';
import {createNewRole} from '../../../actions/role'
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const gender = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'},
    {value: 'other', label: 'Other'},
  ]
  
  class CreateRole extends CommonForm {
    state = { 
      data: {
        username: '',
        password: '',
      }
     }
    onFinish = (values) => {
        this.props.createNewRole(values)
      };
   
      onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      render() { 
    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{padding:"10px 0px"}}>
              <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item>Create Role</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title="Create Role"
              subTitle="Role Management"
              extra={[
                <Button key="1"  type="primary" icon={<TabletFilled />} onClick = { () => history.push('/roles')}>Role List
                </Button>,
              ]}
            >
            </PageHeader>
            <br />
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >
              {this.renderInput('name', 'Role Name', [
                  { required: true, message: 'Role name is required' },
                ])
              }
               <Divider orientation="center">Assign role's access</Divider>
              <Row>
                <Col xs={24}>
                  {this.renderCheckbox('user_management', 'User Management',  [
                      { required: false },
                    ])
                  }
                  {this.renderCheckbox('tv_app_management', 'Ad Management',   [
                      { required: false },
                    ])
                  }
                  {this.renderCheckbox('end_tv_app_user_management', 'App User Management',   [
                      { required: false },
                    ])
                  }
                  {this.renderCheckbox('app_management', 'App Management',   [
                      { required: false },
                    ])
                  }
                </Col>
                
              </Row>
              
              <Form.Item {...tailLayout}> 
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            
            </Form>
    
    </Content>
    )
  }
}

 
export default connect(null,{createNewRole})(CreateRole);