
import React, { Component } from 'react'
import styled from 'styled-components';

const Container = styled.div`
    position: absolute;
    /* background-color: rgba(127, 143, 166,.8); */
    top:40%;
    left: 40%;
    z-index: 1000;
    
`;
class Spinner extends Component {
    state = {  }
    render() { 
        return ( 
            <Container>
                <img src="./loader.gif" alt=""/>
            </Container>
         );
    }
}
 
export default Spinner;