import { Route, Router, Switch } from 'react-router';
import PageLayout from './pages/root/layouts';
import history from './history';
import LoginForm from './pages/root/login';
import { connect } from 'react-redux';
import MessageAlert from './component/messageAlert';
import WrongPage from './component/wrongPage';
import 'antd/dist/antd.css';
import './App.css';
function App(props) {
  return (
    <>
    {props.error ? <MessageAlert type="error" value={props.error} /> : null}
    {props.authSuccess ? <MessageAlert type="success" value={props.authSuccess} /> : null}
      {props.authError ? <MessageAlert type="error" value={props.authError} /> : null}
      {props.authValidationError ? 
        props.authValidationError.map((item,id )=> (
          <MessageAlert type="error" value={item.message} />
        ))
        :
        null
      }
    <Router history={history}>
      <Switch>
        
        <Route path="/opps" component={WrongPage}/>
        <Route path="/login" component = {LoginForm} />
        <Route path="/" component = {PageLayout} />
      </Switch>
    </Router>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    error: state.message.error,
    authSuccess: state.message.authSuccess,
    authError: state.message.authError,
    authValidationError: state.message.authValidationError,
  };
};
export default connect(mapStateToProps)(App);
