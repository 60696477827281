// ======================Auth==
export const LOGIN = 'LOGIN';
            // ==success==
 export const AUTH_SUCCESS = 'AUTH_SUCCESS';
            // ==Error==
 export const AUTH_ERROR = 'AUTH_ERROR';


 
// ====================================================================================User==
export const CREATE_USER = 'CREATE_USER';
export const GET_USERS = 'GET_USERS';
export const DELETE_USER = 'DELETE_USER';
            // ==success==
 export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
 export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
 export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
            // ==Error==
 export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
 export const GET_USER_ERROR = 'GET_USER_ERROR';
 export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

 // ====================================================================================Merchant==
export const CREATE_MERCHANT = 'CREATE_MERCHANT';
export const GET_MERCHANTS = 'GET_MERCHANTS';
export const GET_MERCHANT = 'GET_MERCHANT';
export const DELETE_MERCHANT = 'DELETE_MERCHANT';
            // ==success==
 export const CREATE_MERCHANT_SUCCESS = 'CREATE_MERCHANT_SUCCESS';
 export const GET_MERCHANT_SUCCESS = 'GET_MERCHANT_SUCCESS';
 export const DELETE_MERCHANT_SUCCESS = 'DELETE_MERCHANT_SUCCESS';
            // ==Error==
 export const CREATE_MERCHANT_ERROR = 'CREATE_MERCHANT_ERROR';
 export const GET_MERCHANT_ERROR = 'GET_MERCHANT_ERROR';
 export const DELETE_MERCHANT_ERROR = 'DELETE_MERCHANT_ERROR';
 
 // ====================================================================================SHOP==
export const CREATE_SHOP = 'CREATE_SHOP';
export const GET_SHOPS = 'GET_SHOPS';
export const DELETE_SHOP = 'DELETE_SHOP';
            // ==success==
 export const CREATE_SHOP_SUCCESS = 'CREATE_SHOP_SUCCESS';
 export const GET_SHOP_SUCCESS = 'GET_SHOP_SUCCESS';
 export const DELETE_SHOP_SUCCESS = 'DELETE_SHOP_SUCCESS';
            // ==Error==
 export const CREATE_SHOP_ERROR = 'CREATE_SHOP_ERROR';
 export const GET_SHOP_ERROR = 'GET_SHOP_ERROR';
 export const GET_SHOPS_ERROR = 'GET_SHOPS_ERROR';
 export const DELETE_SHOP_ERROR = 'DELETE_SHOP_ERROR';
// ===================================================================================Driver
export const CREATE_DRIVER = 'CREATE_DRIVER';
export const GET_DRIVERS = 'GET_DRIVERS';
export const GET_SINGLE_DRIVER = 'GET_SINGLE_DRIVER';
export const GET_DRIVERS_ON_MAP = 'GET_DRIVERS_ON_MAP';
                // ==success==
 export const CREATE_DRIVER_SUCCESS = 'CREATE_DRIVER_SUCCESS';
                // ==error==
 export const CREATE_DRIVER_ERROR = 'CREATE_DRIVER_ERROR';
 export const GET_DRIVER_ERROR = 'GET_DRIVER_ERROR';
 export const GET_SINGLE_DRIVER_ERROR = 'GET_SINGLE_DRIVER_ERROR';


// ===================================================================================Warehouse
export const CREATE_WAREHOUSE = 'CREATE_WAREHOUSE';
export const CREATE_WAREHOUSE_MANAGER = 'CREATE_WAREHOUSE_MANAGER';
export const GET_WAREHOUSES = 'GET_WAREHOUSES';
export const GET_WAREHOUSE = 'GET_WAREHOUSE';
export const GET_WAREHOUSE_MANAGER = 'GET_WAREHOUSE_MANAGER';
export const GET_SINGLE_WAREHOUSE_MANAGER = 'GET_SINGLE_WAREHOUSE_MANAGER';
                // ==success==
 export const CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCESS';
 export const CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS';
 export const CREATE_WAREHOUSE_MANAGER_SUCCESS = 'CREATE_WAREHOUSE_MANAGER_SUCCESS';
 export const GET_WAREHOUSE_MANAGER_SUCCESS = 'GET_WAREHOUSE_MANAGER_SUCCESS';
                // ==error==
 export const CREATE_AREA_ERROR = 'CREATE_AREA_ERROR';
 export const CREATE_WAREHOUSE_ERROR = 'CREATE_WAREHOUSE_ERROR';
 export const CREATE_WAREHOUSE_MANAGER_ERROR = 'CREATE_WAREHOUSE_MANAGER_ERROR';
 export const GET_WAREHOUSE_ERROR = 'GET_WAREHOUSE_ERROR';
 export const GET_SINGLE_WAREHOUSE_ERROR = 'GET_SINGLE_WAREHOUSE_ERROR';
 export const GET_SINGLE_MANAGER_ERROR = 'GET_SINGLE_MANAGER_ERROR';
 export const GET_WAREHOUSE_MANAGER_ERROR = 'GET_WAREHOUSE_MANAGER_ERROR';

// ===================================================================================Roles==
export const CREATE_ROLE = 'CREATE_ROLE';
export const GET_ROLES = 'GET_ROLES';
export const DELETE_ROLE = 'DELETE_ROLE';

// ========================================Message
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
//=====================================================================================Pricing
export const GET_PRICING_MODEL = 'GET_PRICING_MODEL';

//=====================================================================================Pricing
export const GET_AREAS = 'GET_AREAS';
            // ==success==
export const CREATE_PRICE_SUCCESS = 'CREATE_PRICE_SUCCESS';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';

            // ==Error==
            
export const CREATE_PRICE_ERROR = 'CREATE_PRICE_ERROR';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';
export const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';
export const GET_ROLE_ERROR = 'GET_ROLE_ERROR';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';


export const SESSION_ERROR = 'SESSION_ERROR';





//=========================Loader

export const LOADEDING = 'LOADEDING';
export const LOADED = 'LOADED';


// =========================RESET

export const RESET_MESSAGES = 'RESET_MESSAGES';
export const RESET_SUCCESS = 'RESET_SUCCESS';


// ============================EditHelper

export const GET_USER = "GET_USER";
export const GET_ROLE = "GET_ROLE";


//=============================== Helper
export const TOPBAR_STATUS = 'TOPBAR_STATUS';
export const GET_CITY = "GET_CITY";
export const GET_SUBCITY = "GET_SUBCITY";
export const GET_THANA = "GET_THANA";
export const GET_AREA = "GET_AREA";


// =======================New Errror=================
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const VALIDATION_AUTH_ERROR = "VALIDATION_AUTH_ERROR";


// ================ Uplad ================
export const TOTAL_SIZE = "TOTAL_SIZE";
export const DOWNLOADED = "DOWNLOADED";
export const PERCENT = "PERCENT";