import {
    GET_PRICING_MODEL,
    GET_AREAS
} from '../actions/types';

const initState = {
    pricing: '',
    area: '',
}


export default (state=initState, action)=>{
    switch (action.type) {
        
        case GET_PRICING_MODEL:
            return {...state, pricing: action.payload};

        case GET_AREAS:
            return {...state, area: action.payload};
            
           
        default:
            return state;
    }
}