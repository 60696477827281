import axios from "axios";
import history from "../history";
import api from "./apis";

import {
 
  ERROR_MESSAGE,
  GET_USERS,
  LOADED,
  LOADEDING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR
} from "./types";

// ==========================App==============
export const createApp = (data) => async (dispatch) => {
  
  dispatch({type: LOADEDING});

  const object = {
    name: data.name,
    status: data.status.toString(),
  };

  try {
    const response = await api(localStorage.getItem("token")).post(
      "/app-management/translator/create",
      { ...object }
    );

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const getApp = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/app-management/app/all"
    );
    
    dispatch({type: LOADED});
    return ( response.data );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updateApp = (data, id) => async (dispatch) => {
  dispatch({type: LOADEDING});

  const object = {
    id,
    state: data?.state,
    version: data?.version,
  };
  dispatch({type: LOADED});

  try {
    const response = await api(localStorage.getItem("token")).put("/app-management/app/update",{ ...object });

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({type: SUCCESS_MESSAGE,payload: "Translator is updated succesfully"});
    
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};
