import React, { Component, useEffect, useState } from 'react'
import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb, Divider, Row, Col } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import { TabletFilled } from '@ant-design/icons';
import history from '../../../history';
import CommonForm, { renderCheckbox, renderInput, renderSelect, renderTextField } from '../../../component/form/form';
import { connect } from 'react-redux';
import {createNewRole,getRoleById,updateRole} from '../../../actions/role'
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
  const auth = [
    {value: true, label: 'Active'},
    {value: false , label: 'Inactive'},
  ]

const EditRole = ({getRoleById,updateRole,location}) => {
  const [data, setData] = useState(null)
  const [roleId, setRoleId] = useState(null)
  const [roleName, setRoleName] = useState(null)
  const [visible, setVisible] = useState(false)
  const [position, setPosition] = useState('bottom')
  const onFinish = (values) => {
            // console.log('Success:', values);
    updateRole(values,roleId)
  };
   
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const [form] = Form.useForm();
  
        useEffect(async() => {
          // const res = await getProfile()
          const search = location.search;
          const id = new URLSearchParams(search).get("id");
          setRoleId(id)
          const res = await getRoleById(id)
          setRoleName(res?.name)
          setData(res)
              form.setFieldsValue({
                name: res?.name,
                active: res?.active,
                user_management : res?.role?.user_management,
                tv_app_management : res?.role?.tv_app_management,
                end_tv_app_user_management : res?.role?.end_tv_app_user_management,
                app_management : res?.role?.app_management,
                
                // mobile_number: res?.mobile_number,
              });
        }, []);
  return ( 
    <Content>
      <Breadcrumb style={{padding:"10px 0px"}}>
               <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
               <Breadcrumb.Item>Edit Role</Breadcrumb.Item>
             </Breadcrumb>
             <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title="Edit Role"
              subTitle="Role Management"
              extra={[
                <Button key="1"  type="primary" icon={<TabletFilled />} onClick = { () => history.push('/roles')}>Role List
                </Button>,
              ]}
            >
            </PageHeader>
            <br />

      {data ? 
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{alignSelf: 'center'}}
            requiredMark={false}
            form={form}
          >
              {renderInput('name', 'Role Name', [
                  { required: true, message: 'Role name is required' },
                ])
              }
               <Divider orientation="center">Assign role's access</Divider>

            <Row>
                <Col xs={24}>
                  {renderCheckbox('user_management', 'User Management', [
                      { required: false },
                    ])
                  }
                  {renderCheckbox('tv_app_management', 'Ad Management',   [
                      { required: false },
                    ])
                  }
                  {renderCheckbox('end_tv_app_user_management', 'App User Management',   [
                      { required: false },
                    ])
                  }
                  {renderCheckbox('app_management', 'App Management',  [
                      { required: false },
                    ])
                  }
                 
                </Col>
              </Row>
              <Form.Item {...tailLayout}> 
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
          </Form>
          : null}

    </Content>
   );
}
 
// export default EditRole;
 
export default connect(null,{createNewRole,getRoleById,updateRole})(EditRole);