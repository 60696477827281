import React, { useEffect } from 'react'
import { Form, Button, Select ,Modal } from 'antd';
import { renderInput, renderSelect } from '../../../component/form/form';

import { connect } from 'react-redux';
import { updateStorage } from "../../../actions/storage";
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]
  

  
const EditModalForm = ({visible, setVisible, updateStorage, refresh, data}) => {
    
      const [form] = Form.useForm();
      useEffect(() => {
        form.setFieldsValue({
          status: data?.status,
          extra_use : data?.extra_use,
          extra_use_api : data?.extra_generate_image,
        });
      }, [])
      
      const onFinish = async (values) => {
        const code = await updateStorage(values, data?.uuid)
        if(code === 201){
          refresh()
          setVisible()
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
     

    return ( 
          <Modal
          title="Update Storage"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                {renderInput('extra_use', 'Extra Use (MB)', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('extra_use_api', 'Image Limit Extension', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderSelect('status', 'Status', [
                    { required: true},
                  ],
                  'Select a option', status, 'none')
                }
                
                <Form.Item {...tailLayout}> 
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              
              </Form>
        </Modal>
     );
}
 
export default connect(null,{updateStorage})(EditModalForm);
