import {
    GET_MERCHANTS,
    DELETE_MERCHANT,
    GET_MERCHANT,
    GET_SHOPS
} from '../actions/types';

const initState = {
    allMerchants: '',
    merchant: [],
    allShops: [],
}


export default (state=initState, action)=>{
    switch (action.type) {
        case GET_MERCHANTS:
            return {...state, allMerchants: action.payload};
        case GET_SHOPS:
            return {...state, allShops: action.payload};
        case GET_MERCHANT:
            return {...state, merchant: action.payload}
            // case GET_MERCHANT:
            //     return {...state, MERCHANT: state.allMerchants.filter(i=>i.MERCHANTId === action.payload)};
                
            case DELETE_MERCHANT:
                return {...state, allMERCHANTs: state.allUsers.filter(i=>i.userId !== action.payload)};
                
        default:
            return state;
    }
}